app-proposal-create,
app-proposal-detail {
  .round {
    background-color: $body;
    border-radius: 8px;
    overflow: hidden !important;
    margin-bottom: 16px;
    border: solid thin transparent;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 20px;
      transform: translateY(-50%);
      left: 8px;
      width: 16px;
      height: 16px;
      border: solid thin $border;
      border-radius: 8px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 20px;
      transform: translateY(-50%);
      left: 11px;
      width: 10px;
      height: 10px;
      border-radius: 8px;
    }
    .round-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 500;
    }
    &:hover {
      background-color: lighten($body, 2%);
    }
    &.active {
      border-color: $blue;

      &:before {
        content: '';
        position: absolute;
        border: solid thin $blue;
      }
      &:after {
        background: $blue;
      }
      &:hover {
        background-color: $body;
      }
    }
    &.details {
      height: auto;
      .round-title {
        text-overflow: initial;
        overflow: visible;
        white-space: normal;
      }
    }
  }
}
app-proposal-create-step-one {
  .round {
    .cover {
      width: 177px;
      height: 112px;
      min-height: 112px;
    }
    .general-info {
      @media (max-width: 640px) {
        flex-direction: column;
        button.text-blue {
          order: 3;
        }
        .pl-32.pt-16 {
          order: 2;
        }
        .cover {
          width: 100%;
          height: auto;
        }
      }
    }
    &.video-cover {
      video {
        width: 177px;
        height: 100px;
        @media (max-width: 640px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
app-proposal-create,
app-proposal-teaser,
app-distribution-create {
  background-color: rgba($body, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  .tabs-create-panel {
    > .tabs-header {
      .tab-label {
        @media (max-width: 640px) {
          label {
            display: none !important;
          }
        }
      }
    }
  }
  section {
    width: 840px;
    height: 100%;
    background-color: $white;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: -6px 0 30px 0 rgba($black, 0.1);
    @media (max-width: 991px) {
      width: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .step-tab {
    > .tabs-header {
      .tab-label {
        @media (max-width: 640px) {
          label {
            display: none !important;
          }
        }
      }
    }
  }

  .tabs-body {
    flex-grow: 1;
    padding: 24px;
    height: calc(100% - 40px);
    @media (max-width: 640px) {
      padding: 16px;
    }
  }
  image-cropper {
    .overlay,
    .cropper {
      margin-left: 0 !important;
    }
  }

  .add-media {
    background-color: $body;
    border: dashed 1px $border;
    border-radius: 8px;
    display: flex;
    margin-bottom: 16px;
  }

  .card-preview {
    position: relative;
    width: full;
    @include ratio(16, 9);
    .description {
      @include line-clamp(2, 1.456);
      min-height: 46px;
    }
  }
}
app-distribution-create {
  .tabs-body {
    padding: 0 !important;
    height: 100% !important;
  }
}
app-proposal-detail {
  .round {
    background-color: $white;
    margin: 0 !important;
    width: 100% !important;
  }
}
app-proposal-teaser {
  app-round-overview {
    .round {
      color: $white;
      app-icon {
        fill: $white;
      }
      &:hover {
        background-color: $sidebar !important;
      }
      .grid.border-gray-border {
        border-color: $blue !important;
      }
      .tabs-header {
        border-color: rgba($white, 30%) !important;
        .tab-label {
          color: rgba($white, 30%) !important;
          &.active {
            color: $white !important;
            &::after {
              background-color: $white !important;
            }
          }
        }
      }
      button.expand-buton {
        span {
          &:before,
          &:after {
            background-color: $white !important;
          }
        }
      }
    }
  }
}

app-review-detail,
app-proposal-create,
app-proposal-teaser,
app-proposal-detail {
  .round {
    margin: 0 16px 16px;
    width: calc(100% - 32px);
    overflow: hidden;

    app-video-player,
    app-dcp-image {
      width: auto !important;
      height: 112px;
    }

    .general-info {
      height: 112px;
      @media (max-width: 640px) {
        flex-direction: row !important;
        .header {
          padding-top: 0;
          order: inherit;
        }
      }
    }
    &.details {
      .general-info {
        height: auto;
      }
    }
    button.expand-buton {
      margin-top: 94px !important;
      align-self: flex-start;
      @media (max-width: 640px) {
        order: inherit !important;
      }
    }
  }
}
app-review-detail,
app-proposal-create {
  .round {
    margin: 0;
    margin-bottom: 16px;
    width: 100%;
  }
}

.router-wrapper {
  app-distribution-create,
  app-proposal-create {
    background-color: transparent;
    position: inherit;
    max-width: 1200px;
    padding: 16px 0;
    margin: auto;

    section {
      width: 100% !important;
      height: 100%;
      background-color: $white;
      border-radius: 8px;
    }
  }
}
